import React from "react";
import styled, { css } from "styled-components";
import { colors } from "../../lib/constant";
import { fontSize, fontFamily, product_sans } from "../../lib/fonts";
import { darken, lighten } from "polished";

const Icon = styled.div`
  margin-left: 10px;
  height: 8px;
  transition: transform 0.2s;
`;

export const Button = styled(
  ({
    children,
    icon,
    className,
    secondary,
  }: {
    children: React.ReactNode;
    icon?: React.ReactNode;
    className?: string;
    secondary?: boolean;
  }) => (
    <span className={className}>
      {children}
      {icon && <Icon>{icon}</Icon>}
    </span>
  ),
)`
  padding: 17px 20px;
  background: ${colors.primary};
  box-shadow: 0 2px 10px -2px rgba(60, 57, 77, 0.15);
  border-radius: 8px;
  color: #fff;
  position: relative;
  overflow: hidden;
  transition: padding 0.2s ease-out, border-color 0.2s ease-out,
    color 0.2s ease-out;
  z-index: 1;
  display: inline-flex;
  cursor: pointer;
  ${fontSize("15px")};
  line-height: 18px;
  ${fontFamily(product_sans.regular)};

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0px;
    left: -100%;
    bottom: 0;
    background-color: ${darken("0.1", colors.primary)};
    opacity: 0;
    width: 100%;
    transition: left 0.2s ease-out, opacity 0.2s ease-out;
  }

  &:hover::before {
    left: 0;
    opacity: 1;
  }

  &:hover ${Icon} {
    transform: translateX(5px);
  }

  &:active {
    transform: scale(0.95);
  }

  ${(p) =>
    p.secondary &&
    css`
      background: ${colors.secondary};

      &:before {
        background-color: ${lighten("0.12", colors.secondary)};
      }
    `}
`;
