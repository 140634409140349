import * as React from "react";
import styled from "styled-components";

const SvgCheck10 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg data-name="Calque 1" viewBox="0 0 100 80" {...props}>
    <path
      d="M96.83 3.17a10.85 10.85 0 010 15.33L38.5 76.83A10.85 10.85 0 0130 80a10.85 10.85 0 01-7.46-2.22L3.17 58.5a10.84 10.84 0 0114.39-16.17L30 54.67l51.5-51.5a10.85 10.85 0 0115.33 0z"
      fill="#1d1d1b"
      fillRule="evenodd"
    />
  </svg>
);

export default styled(SvgCheck10)``;
