import Android24 from "./Android24";
export const IconAndroid24 = Android24;

import ArrowB6 from "./ArrowB6";
export const IconArrowB6 = ArrowB6;

import ArrowL16 from "./ArrowL16";
export const IconArrowL16 = ArrowL16;

import ArrowR16 from "./ArrowR16";
export const IconArrowR16 = ArrowR16;

import ArrowRight10 from "./ArrowRight10";
export const IconArrowRight10 = ArrowRight10;

import ArrowRight14 from "./ArrowRight14";
export const IconArrowRight14 = ArrowRight14;

import ArrowT6 from "./ArrowT6";
export const IconArrowT6 = ArrowT6;

import Basic30 from "./Basic30";
export const IconBasic30 = Basic30;

import Check10 from "./Check10";
export const IconCheck10 = Check10;

import Check20 from "./Check20";
export const IconCheck20 = Check20;

import Cross10 from "./Cross10";
export const IconCross10 = Cross10;

import Cross20 from "./Cross20";
export const IconCross20 = Cross20;

import Desktop50 from "./Desktop50";
export const IconDesktop50 = Desktop50;

import ExportCustom from "./ExportCustom";
export const IconExportCustom = ExportCustom;

import ExportStandard from "./ExportStandard";
export const IconExportStandard = ExportStandard;

import Facebook16 from "./Facebook16";
export const IconFacebook16 = Facebook16;

import Filter16 from "./Filter16";
export const IconFilter16 = Filter16;

import Instagram16 from "./Instagram16";
export const IconInstagram16 = Instagram16;

import Ios24 from "./Ios24";
export const IconIos24 = Ios24;

import Laptop50 from "./Laptop50";
export const IconLaptop50 = Laptop50;

import Linkedin16 from "./Linkedin16";
export const IconLinkedin16 = Linkedin16;

import Love30 from "./Love30";
export const IconLove30 = Love30;

import Magnify16 from "./Magnify16";
export const IconMagnify16 = Magnify16;

import Mail30 from "./Mail30";
export const IconMail30 = Mail30;

import Message30 from "./Message30";
export const IconMessage30 = Message30;

import Mobile42 from "./Mobile42";
export const IconMobile42 = Mobile42;

import Offer30 from "./Offer30";
export const IconOffer30 = Offer30;

import Pinterest16 from "./Pinterest16";
export const IconPinterest16 = Pinterest16;

import Question20 from "./Question20";
export const IconQuestion20 = Question20;

import Scanner30 from "./Scanner30";
export const IconScanner30 = Scanner30;

import Screen50 from "./Screen50";
export const IconScreen50 = Screen50;

import Separator50 from "./Separator50";
export const IconSeparator50 = Separator50;

import Shape30 from "./Shape30";
export const IconShape30 = Shape30;

import Snapchat16 from "./Snapchat16";
export const IconSnapchat16 = Snapchat16;

import Tablet50 from "./Tablet50";
export const IconTablet50 = Tablet50;

import Twitter16 from "./Twitter16";
export const IconTwitter16 = Twitter16;

import Viadeo16 from "./Viadeo16";
export const IconViadeo16 = Viadeo16;

import Whatsapp16 from "./Whatsapp16";
export const IconWhatsapp16 = Whatsapp16;

import Youtube16 from "./Youtube16";
export const IconYoutube16 = Youtube16;
