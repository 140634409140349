import { createGlobalStyle } from "styled-components";
import { product_sans, fontFace, fontFamily } from "../../lib/fonts";
import "react-lazy-load-image-component/src/effects/opacity.css";

// tslint:disable-next-line
const normalize = require("!css-loader!normalize.css/normalize.css");

export const globalStyles = (rootName = "html,body") => `
  ${fontFace(product_sans.regular)}
  ${fontFace(product_sans.medium)}
  ${fontFace(product_sans.bold)}
  ${normalize.toString()}

  :root {
    font-size: 16px;
  }

  html,
  body {
    margin: 0;
    height: 100%;
    ${fontFamily(product_sans.regular)};
    color: #323B4D;
  }

  html {
    /* Adjust font size */
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    /* Font varient */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  }
`;

export const GlobalStyles = createGlobalStyle`

  ${globalStyles()}

  h1, h2, h3 {
    ${fontFamily(product_sans.bold)};
    font-weight: normal;
    margin-top:0;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
  }

  button:focus {
    outline:0;
  }
`;
