import React from "react";
import styled, { css } from "styled-components";
import { Button } from "./ui/Button";
import { Select } from "./ui/Select";
import { colors } from "../lib/constant";
import { mediaQuery } from "react-styled-mediaquery";
import { Link } from "react-scroll";
import { fontSize, fontFamily, product_sans } from "../lib/fonts";
import { FormattedMessage } from "react-intl";
import { languagesSelectorLabels, useLocale } from "../lib/MainProvider";
import logo from "../../assets/logo.svg";

export const HeaderNavigation = ({
  scrollEnabled = true,
  fixed = false,
}: {
  scrollEnabled?: boolean;
  fixed?: boolean;
}) => {
  const { locale, setLocale } = useLocale();
  const links = [
    {
      reference: "features",
      label: (
        <FormattedMessage id="nav.link.features" defaultMessage="Features" />
      ),
    },
    {
      reference: "options",
      label: (
        <FormattedMessage id="nav.link.options" defaultMessage="Options" />
      ),
    },
    {
      reference: "partnerhub",
      label: (
        <FormattedMessage
          id="nav.link.partnerhub"
          defaultMessage="Partner Hub"
        />
      ),
    },
    {
      reference: "price",
      label: (
        <FormattedMessage id="nav.link.pricing" defaultMessage="Pricing" />
      ),
    },
  ];

  return (
    <Wrapper fixed={fixed}>
      <LayoutNav>
        <Container>
          <Link href="#" to="header" smooth={true} duration={700}>
            <img src={logo} alt="Partner Locator" height="40" />
          </Link>

          <NavigationUl>
            {links.map(({ label, reference }, i) => (
              <NavListItem key={i}>
                <NavigationLink
                  scrollEnabled={scrollEnabled}
                  href={"/#" + reference}
                  to={reference}
                  activeClass="active"
                  hashSpy={true}
                  spy={true}
                  smooth={true}
                  duration={700}
                  offset={-95}
                >
                  {label}
                </NavigationLink>
              </NavListItem>
            ))}

            {typeof window !== "undefined" && (
              <Select
                items={languagesSelectorLabels}
                value={locale}
                onChange={(e) => {
                  setLocale(e.currentTarget.value as any);
                }}
              />
            )}

            <NavListItem>
              <NavigationLinkButton
                scrollEnabled={scrollEnabled}
                href="/#contact"
                to="contact"
                hashSpy={true}
                spy={true}
                smooth={true}
                duration={700}
              >
                <Button secondary>
                  <FormattedMessage
                    id="nav.link.contact"
                    defaultMessage="Contact"
                  />
                </Button>
              </NavigationLinkButton>
            </NavListItem>
          </NavigationUl>
        </Container>
      </LayoutNav>
    </Wrapper>
  );
};

const LayoutNav = styled.div`
  max-width: 1050px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavigationUl = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
`;

const NavListItem = styled.li`
  margin: 0 15px;

  &:last-child {
    margin-right: 0;
  }
`;

const ScrollLinkWithCondition = ({
  scrollEnabled,
  href,
  children,
  className,
  ref,
  ...props
}: { scrollEnabled?: boolean } & React.ComponentProps<typeof Link>) =>
  scrollEnabled ? (
    <Link {...{ href, children, className }} {...props} />
  ) : (
    <a {...{ href, children, className }} />
  );

const NavigationLink = styled(ScrollLinkWithCondition)`
  color: ${colors.greyDrk};
  padding: 10px 0;
  position: relative;
  ${fontSize("15px")};
  ${fontFamily(product_sans.medium)};
  line-height: 18px;

  &:after {
    content: "";
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    width: 10px;
    height: 2px;
    background: ${colors.secondary};
    position: absolute;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover:after,
  &.active:after {
    opacity: 1;
  }
`;

const NavigationLinkButton = styled(NavigationLink)`
  &:after {
    display: none;
  }
`;

const Wrapper = styled.div<{ fixed?: boolean }>`
  position: relative;
  padding-top: ${(p) => (p.fixed ? `20px` : `60px`)};
  padding-bottom: 20px;
  width: 100%;
  z-index: 99;

  ${(p) =>
    p.fixed &&
    css`
      background: #fff;
    `}

  ${mediaQuery("<=", "desktop")`
    padding-top: 30px;
  `}
`;
